import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import axios from 'axios'

const app = createApp(App)

app.use(ElementPlus)


const w = window
w.w = w

w.clone = obj => JSON.parse(JSON.stringify(obj))

w.ajax = function(f, p, cb, err) {
    axios.post(
        'https://t.iily.cc/index/res.php?f=' + f + '&_=' + new Date().getTime(),
        p || {}, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
    ).then(d => {
        if (cb) cb(d.data.data)
    }).catch(d => {
        if (err) err();
    });
}

app.mount('#app')