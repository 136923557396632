<template>
    <div class="index">
        <div style="height: 100px"></div>
        <div class="search">
            <el-input v-model="searchStr" placeholder="" class="searchText" @keydown="search(false, $event)">
                <template #prepend>
                    <el-select v-model="searchType" style="width: 70px" @change="searchTypeChange">
                        <el-option label="百度" value="百度"></el-option>
                        <el-option label="谷歌" value="谷歌"></el-option>
                    </el-select>
                </template>
                <template #append>
                    <el-button :icon="Search" @click="search(true)"></el-button>
                </template>
            </el-input>
        </div>
        <!-- <el-divider>网站</el-divider> -->
        <div style="height: 80px"></div>
        <div class="website">
            <div
                v-for="d in data"
                :key="d.id"
                v-show="d.show || option || !hide"
            >
                <div class="list">
                    <a
                        class="item"
                        v-for="s in d.website"
                        :key="s.id"
                        :href="s.url"
                        v-show="(!s.hide || (s.hide && !hide)) && !option"
                    >
                        <el-tag size="large" :type="s.color">{{
                            s.name
                        }}</el-tag>
                    </a>
                    <div
                        :draggable="true"
                        @dragstart="drag.start(s)"
                        @dragend="drag.ok(s)"
                        @dragenter="drag.enter(s)"
                        class="item"
                        :class="[
                            s.drag ? 'dragon' : '',
                            s.drop ? 'dropon' : '',
                        ]"
                        v-for="s in d.website"
                        :key="s.id"
                        v-show="option && !s.isdel"
                    >
                        <div class="drag">+</div>
                        <el-tag
                            size="large"
                            :type="s.color"
                            :closable="true"
                            :hit="true"
                            @click="updateClick(s)"
                            @close="s.isdel = true"
                        >
                            {{ s.name }}</el-tag
                        >
                    </div>
                    <div
                        class="item add"
                        v-show="option"
                        @click="addClick(d.name)"
                    >
                        +
                    </div>
                </div>
            </div>
            <!-- <el-collapse v-model="collapse">
                <el-collapse-item
                    :name="d.id"
                    v-for="d in data"
                    :key="d.id"
                    v-show="d.show || option || !hide"
                >
                    <template #title>
                        <div style="
                                width: 100%;
                                text-align: center;
                                font-size: 14px;
                            ">
                            {{ d.name }}
                        </div>
                    </template>
                    <div class="list">
                        <a class="item" v-for="s in d.website" :key="s.id" :href="s.url"
                            v-show="(!s.hide || (s.hide && !hide)) && !option">
                            <el-tag size="large" :type="s.color">{{
                                    s.name
                            }}</el-tag>
                        </a>
                        <div :draggable="true" @dragstart="drag.start(s)" @dragend="drag.ok(s)"
                            @dragenter="drag.enter(s)" class="item" :class="[
                                s.drag ? 'dragon' : '',
                                s.drop ? 'dropon' : '',
                            ]" v-for="s in d.website" :key="s.id" v-show="option && !s.isdel">
                            <div class="drag">+</div>
                            <el-tag size="large" :type="s.color" :closable="true" :hit="true" @click="updateClick(s)"
                                @close="s.isdel = true">
                                {{ s.name }}</el-tag>
                        </div>
                        <div class="item add" v-show="option" @click="addClick(d.name)">
                            +
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse> -->
        </div>
        <div class="hideBtn left" @click="save"></div>
        <div class="hideBtn right" @click="chide"></div>
    </div>

    <el-dialog v-model="update.show" title="添加" width="300px">
        <div class="addDlgitem">
            <div class="label">类别：</div>
            <div class="text">
                <el-input v-model="update.groupname" placeholder="" :disabled="true" />
            </div>
        </div>
        <div class="addDlgitem">
            <div class="label">名称：</div>
            <div class="text">
                <el-input v-model="update.name" placeholder="" />
            </div>
        </div>
        <div class="addDlgitem">
            <div class="label">网址：</div>
            <div class="text">
                <el-input v-model="update.url" placeholder="" />
            </div>
        </div>
        <div class="addDlgitem">
            <div class="label">颜色：</div>
            <div class="text">
                <el-select v-model="update.color">
                    <el-option label="primary" value=""></el-option>
                    <el-option label="success" value="success"></el-option>
                    <el-option label="info" value="info"></el-option>
                    <el-option label="warning" value="warning"></el-option>
                    <el-option label="danger" value="danger"></el-option>
                </el-select>
            </div>
        </div>
        <div class="addDlgitem">
            <div class="label">Hide：</div>
            <div class="text">
                <el-switch v-model="update.hide" />
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="update.show = false">取消</el-button>
                <el-button type="primary" @click="updateOk">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
const searchStr = ref("");
const searchTypeStr = w.localStorage.searchTypeStr || "百度";
const searchType = ref(searchTypeStr);

function search(c, e) {
    if (!c && e.keyCode != 13) return;
    if (!searchStr.value) {
        loadData(true);
        return;
    }
    if (searchType.value == "百度") {
        w.location =
            "https://www.baidu.com/s?wd=" +
            w.encodeURIComponent(searchStr.value);
    } else if (searchType.value == "谷歌") {
        w.location =
            "https://www.google.com/search?q=" +
            w.encodeURIComponent(searchStr.value);
    }
}

function searchTypeChange(val) {
    w.localStorage.searchTypeStr = val;
}

const data = ref([]);
const collapse = ref([]);

let grouplist, websitelist;

function loadData(refresh) {
    if (!refresh) {
        grouplist = w.localStorage.grouplist;
        websitelist = w.localStorage.websitelist;
    } else {
        grouplist = websitelist = null;
    }
    if (!grouplist || !websitelist) {
        w.ajax("grouplist", {}, (d1) => {
            grouplist = d1.res;
            w.localStorage.grouplist = JSON.stringify(grouplist);
            w.ajax("websitelist", {}, (d2) => {
                websitelist = d2.res;
                w.localStorage.websitelist = JSON.stringify(websitelist);
                setData();
            });
        });
    } else {
        grouplist = JSON.parse(grouplist);
        websitelist = JSON.parse(websitelist);
        setData();
    }
}

function setData() {
    grouplist.forEach((d) => {
        d.website = [];
        d.show = false;
        collapse.value.push(d.id);
    });
    websitelist.forEach((d) => {
        if (d.color == "primary") d.color = "";
        d.isdel = false;
        if (typeof d.hide === "string") d.hide = d.hide == 1;
        d.drag = false;
        d.drop = false;
        let g = grouplist.find((g) => g.name == d.groupname);
        if (g) {
            g.website.push(d);
            if (!d.hide) g.show = true;
        }
    });
    data.value = grouplist;
}

loadData();

const hide = ref(!!w.localStorage.hide);
const option = ref(false);

function setHide() {
    w.localStorage.hide = hide.value ? "" : "1";
    hide.value = !!w.localStorage.hide;
}

const update = reactive({
    id: 0,
    show: false,
    name: "",
    url: "",
    groupname: "",
    color: "primary",
    hide: false,
});

function addClick(groupname) {
    update.id = 0;
    update.show = true;
    update.name = "";
    update.url = "";
    update.groupname = groupname;
    update.color = "";
    update.hide = false;
}

function updateClick(d) {
    update.id = d.id;
    update.show = true;
    update.name = d.name;
    update.url = d.url;
    update.groupname = d.groupname;
    update.color = d.color;
    update.hide = d.hide;
}

function updateOk() {
    if (update.id == 0)
        data.value.forEach((d1) => {
            if (d1.name == update.groupname) {
                const d = w.clone(update);
                d.isdel = false;
                d.drag = false;
                d.drop = false;
                delete d.show;
                d1.website.push(d);
            }
        });
    else
        data.value.forEach((d1) => {
            d1.website.forEach((d2) => {
                if (d2.id == update.id) {
                    d2.name = update.name;
                    d2.url = update.url;
                    d2.color = update.color;
                    d2.hide = update.hide;
                }
            });
        });
    update.show = false;
}

const drag = {
    drop: null,
    start(d) {
        setTimeout(() => {
            d.drag = true;
            d.drop = false;
        }, 10);
    },
    enter(d) {
        data.value.forEach((d1) =>
            d1.website.forEach((d2) => (d2.drop = false))
        );
        d.drop = true;
        this.drop = d;
    },
    ok(d) {
        d.drag = false;
        data.value.forEach((d1) =>
            d1.website.forEach((d2) => (d2.drop = false))
        );
        if (!this.drop) return;
        d.groupname = this.drop.groupname;
        d: for (let i = 0; i < data.value.length; i++) {
            for (let j = 0; j < data.value[i].website.length; j++) {
                let s = data.value[i].website[j];
                if (s == d) {
                    data.value[i].website.splice(j, 1);
                    break d;
                }
            }
        }
        d: for (let i = 0; i < data.value.length; i++) {
            for (let j = 0; j < data.value[i].website.length; j++) {
                let s = data.value[i].website[j];
                if (s == this.drop) {
                    data.value[i].website.splice(j, 0, d);
                    break d;
                }
            }
        }
        this.drop = null;
    },
};

function save() {
    option.value = !option.value;
    if (option.value) return;
    let sort = 1;
    const json = [];
    data.value.forEach((d1) =>
        d1.website.forEach((d2) => {
            const d = w.clone(d2);
            d.sort = sort++;
            d.hide = d.hide ? 1 : 0;
            json.push(d);
        })
    );
    var params = new FormData();
    params.append("data", JSON.stringify(json));
    w.ajax("savewebsite", params, () => {
        w.localStorage.removeItem("grouplist");
        w.localStorage.removeItem("websitelist");
        loadData();
    });
}

function chide() {
    hide.value = !w.localStorage.hide;
    w.localStorage.hide = hide.value ? "1" : "";
}
</script>

<style>
body {
    margin: 0;
    width: 100vw;
    height: 100vh;
}

.el-collapse,
.el-collapse-item,
.el-collapse-item__header,
.el-collapse-item__wrap,
.el-collapse-item__content {
    border: none !important;
}

.el-collapse-item__arrow {
    display: none !important;
}

.grow1 {
    flex-grow: 1;
}

.index {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.index>.hideBtn {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
}

.index>.left {
    left: 0;
}

.index>.right {
    right: 0;
}

.index>.search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    color: #000 !important;
}

.index>.search .searchText {
    background: #fff;
    width: 600px;
}

.index>.website {
    width: 80vw;
}

.index>.website .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.index>.website .list>.item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    text-decoration: none;
}

.index>.website .list>.item>.drag {
    color: #fff;
    background-color: rgb(181, 186, 230);
    border-radius: 5px 0 0 5px;
    padding: 0 3px;
    cursor: move;
}

.index>.website .list>.item>span>span {
    font-size: 16px !important;
}

.index>.website .list>.add {
    font-size: 22px;
    color: blue;
    cursor: pointer;
}

.index>.website .list>.dragon {
    display: none;
}

.index>.website .list>.dropon {
    padding-left: 100px;
}

.addDlgitem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
}

.addDlgitem>.label {
    width: 50px;
    text-align: right;
    margin-right: 10px;
}

.addDlgitem>.text {
    width: 200px;
}
</style>
